import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Card, CardActions, CardContent, CardHeader, Grid, Button } from '@material-ui/core';
import StarIcon from '@material-ui/icons/StarBorder';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../withRoot';
import Layout from "../components/layout";
import SEO from "../components/seo";

const styles = theme => ({
  root: {
    paddingTop: theme.spacing.unit * 2,
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing.unit * 2,
  },
  cardActions: {
    justifyContent: 'center',
    alignItems: 'baseline',
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing.unit * 2,
    },
  },
  heroContent: {
    maxWidth: 700,
    margin: '0 auto',
    color: '#FFF',
    padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`,
  },
});

const tiers = [
  {
    title: 'Guests',
    subheader: 'Get WedInk as a Wedding Guest',
    price: '0',
    description: [
      'Access Unlimited Weddings',
      'Get wedding schedule & other details',
      'Post & Share Photos',
      'Get instant updates on events',
      'Use on any Device',
    ],
    buttonText: 'Download WedInk',
    buttonVariant: 'outlined',
    link: '/',
  },
  {
    title: 'Couples',
    subheader: 'Get your wedding on WedInk',
    price: '0',
    description: [
      'Onboard your Wedding',
      'Invite unlimited guests',
      'Setup secret access code for wedding',
      'Modify wedding details in realtime',
      'Send notification to all guests',
      'Export wedding feed photos anytime',
    ],
    buttonText: 'Request FREE onboarding at support@wedink.app',
    buttonVariant: 'outlined',
    link: 'support@wedink.app',
    linkType: 'email',
  },
];

function About(props) {
  const { classes } = props;

  return (
    <Layout>
      <SEO title="Home" keywords={[`wedink`, `wedding`]} />
      <div className={classes.root}>
        <div className={classes.heroContent}>
          <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom style={{ color: "#FFF" }}>
            Get WedInk
          </Typography>
          <Typography variant="h6" align="center" color="textSecondary" component="p" style={{ color: "#FFF" }}>
            WedInk lets you onboard your wedding on our app platform and lets you create a customized experience for your guests.
            <br/><br/>
            WedInk is currently in beta and wedding onboardings are invite only.
          </Typography>
        </div>
        <Grid container spacing={40} alignItems="center">
          {tiers.map(tier => (
            // Enterprise card is full width at sm breakpoint
            <Grid item key={tier.title} xs={12} md={6}>
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Pro' ? <StarIcon /> : null}
                  className={classes.cardHeader}
                />
                <CardContent>
                  {tier.description.map(line => (
                    <Typography variant="subtitle1" align="center" key={line}>
                      {line}
                    </Typography>
                  ))}
                </CardContent>
                <CardActions className={classes.cardActions}>
                  <a href={tier.linkType === 'email' ? `mailto:${tier.link}` : tier.link}>
                    <Button fullWidth variant={tier.buttonVariant} color="primary">
                      {tier.buttonText}
                    </Button>
                  </a>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Typography variant="subheading" align="center" style={{ color: "#FFF", marginTop:"2rem" }}>
            Get in touch, send us an email at support@wedink.app to request an invite.
          </Typography>
      </div>
    </Layout>
  );
}

About.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(About));